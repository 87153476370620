import BisnodeSweden from './bisnode_se';
import BisnodeNorway from './bisnode_no';

export default {

    /** @namespace bisnode.sweden */
    sweden: BisnodeSweden,

    /** @namespace bisnode.norway */
    norway: BisnodeNorway
    
}