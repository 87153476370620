import remote from '../helpers/remote';

export default class BisnodeNorway {

  /**
   * Get credit info about a company
   * 
   * @memberof bisnode.norway
   * 
   * @param {string} orgNr Organization number to perform a credit check on
   * 
   * @returns {json} Credit info
   */
  static async getCreditInfo(orgNr) {

    let res = await remote.call({
        path: `/bisnode/credit-no?regNumber=${orgNr.replace(/\D/g,'')}`,
        method: "GET"
    });
  
    return res.data;

  }


}